<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <AthleteFeed></AthleteFeed>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AthleteDashboard',
  components: {
    AthleteFeed: () => import('@/components/feed/AthleteFeed'),
  },
};
</script>
